/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { MetadataContent } from '@jutro/uiconfig';
import { withIntl, TranslatorContext } from '@jutro/locale';
import { IconButton, Tooltip, Button, Link, withModalContext } from '@jutro/components';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { BusinessConstant, EdgeErrorParser } from 'pv-portals-util-js';
import { GatewayBillingService } from 'gw-capability-gateway-billing';
import { GatewayDocumentService } from 'gw-capability-gateway-document';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import DelinquencyUtil from 'pv-portals-util-js/DelinquencyUtil';
import InvoiceConfirmationPopup from '../InvoiceConfirmationPopup/InvoiceConfirmationPopup.jsx';
import CollectionMethodChangePopup from '../CollectionMethodChangePopup/CollectionMethodChangePopup.jsx';
import policyBillingStyles from './Billing.module.scss';
import metadata from './Billing.metadata.json5';
import messages from './Billing.messages';
import gatewayMessages from '../../gateway.messages';

const BC = 'bc';
const INVOICETYPE_PV = 'InvoiceType_PV';
const COLLECTIONMETHOD_PV = 'CollectionMethod_PV';

class PolicyBilling extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({}).isRequired,
        fromAccountLanding: PropTypes.shape({
            policyDetailsData: PropTypes.shape({
                policyResponse: PropTypes.shape({
                    policyNumber: PropTypes.string,
                    account: PropTypes.shape({
                        accountNumber: PropTypes.string
                    })
                })
            }),
            billingData: PropTypes.shape({})
        }).isRequired,
        intl: PropTypes.func.isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired
    };

    state = {
        billingData: [],
        currentBillingData: {},
        billingInvoiceData: [],
        policyPeriodDropDownOptions: [],
        selectedPeriod: '',
        isBillingInfoAvailable: true,
        billingDisbursementData: [],
        actionButtonVisible: true,
        showInvoiceConfirmationModal: false,
        showCollectionMethodChangeModal: false,
        invoiceDocuments: [],
        invoicePayment: {},
    };

    componentDidMount() {
        this.getDisbursementsInfo();
        const billingData = this.props?.fromAccountLanding?.billingData?.policyPeriodBillingSummaries;

        if (billingData?.length > 0) {
            let indexToShow = 0;
            let previousTermNumber = billingData[0].termNumber_PV;
            billingData.forEach((item, index) => {
                if (item.termNumber_PV > previousTermNumber) {
                    indexToShow = index;
                    previousTermNumber = item.termNumber_PV;
                }
            });
            this.showBillingSummaryInfo(indexToShow);
        } else {
            this.setState({ isBillingInfoAvailable: false });
        }
    }

    getDisbursementsInfo = async () => {
        const {
            fromAccountLanding,
            authHeader,
            modalContext
        } = this.props;
        const policyNumber = _.get(fromAccountLanding, 'policyDetailsData.policyResponse.policyNumber');
        try {
            const disbursementsInfo = await GatewayBillingService.getDisbursementsInfo(policyNumber, authHeader);
            if (!_.isEmpty(disbursementsInfo)) {
                const disbursementData = this.getDisbursementData(disbursementsInfo);
                this.setState({ billingDisbursementData: disbursementData });
            }
        } catch (e) {
            modalContext.showAlert({
                title: gatewayMessages.modalError,
                message: messages.errorLoadingDisbursement,
                status: 'error',
                icon: 'mi-error-outline',
                cancelButtonText: commonMessages.close
            });
        };
    }

    showBillingSummaryInfo = (index) => {
        const billingData = this.props?.fromAccountLanding?.billingData?.policyPeriodBillingSummaries;
        const invoiceData = billingData[index].invoices;
        const paymentScheduleData = this.getPaymentScheduleData(invoiceData);
        const openInvoiceData = this.getOpenInvoiceData(invoiceData);
        this.getInvoiceDocuments(billingData);
        invoiceData.forEach((invoice) => {
            this.calcInvoiceBRBATKFlag(invoice);
        });
        this.setState({
            billingInvoiceData: paymentScheduleData,
            billingOpenInvoiceData: openInvoiceData,
            currentBillingData: billingData[index],
            policyPeriodDropDownOptions: this.getPolicyPeriodDropDownOptions(billingData),
            selectedPeriod: index,
            invoiceData: invoiceData,
            invoicePayment: {},
        });
    };

    handlePolicyPeriodChange = (policyPeriodIndex) => {
        this.showBillingSummaryInfo(policyPeriodIndex);
    };

    getInvoiceDocuments = async (billingData) => {
        const docUIDs = [];
        billingData?.forEach((policyPeriodBillingSummary) => {
            policyPeriodBillingSummary.invoices.forEach((invoice) => {
                if (invoice.invoiceDocUID_PV && invoice.invoiceDocUID_PV.toLowerCase() !== 'none') {
                    docUIDs.push(invoice.invoiceDocUID_PV);
                }
            });
        });
        const filteredDocUIDS = docUIDs.filter((id) => id !== undefined);

        if (filteredDocUIDS?.length > 0) {
            const {
                authHeader,
            } = this.props;
            try {
                const documents = await GatewayBillingService.getDocuments(filteredDocUIDS, authHeader);
                this.setState({ invoiceDocuments: documents });
            } catch (error) {
                this.setState({ invoiceDocuments: undefined });
            }
        }
    }

    handleCancel = () => {
        this.setState({
            showInvoiceConfirmationModal: false,
            showCollectionMethodChangeModal: false
        });
    };

    /*
    * Changes CollectionMethod for a given invoice
    */
    handleCollectionMethodChangeConfirm = async (accountNo, invoiceNumber, eventType, invoiceAmount, isPaymentDone) => {

        const { authHeader, modalContext } = this.props;
        const { invoiceData } = this.state;

        try {
            await GatewayBillingService.changeCollectionMethod(accountNo, invoiceNumber, eventType, isPaymentDone, authHeader);

            const selectedInvoice = invoiceData.find((obj) => obj.invoiceNumber === invoiceNumber);

            // NOTE: BC already has more fields changed on this invoice, we update bare minimum just to show correct UI
            selectedInvoice.eventType = eventType;
            selectedInvoice.flag = (eventType === 'DRQ_PV');

            this.setState({
                showCollectionMethodChangeModal: false,
                showInvoiceConfirmationModal: false,
                invoiceData: invoiceData
            });
        } catch (error) {
            const msg = EdgeErrorParser.getErrorMessage(error);
            modalContext.showAlert({
                title: gatewayMessages.modalError,
                message: (msg || gatewayMessages.gatewayViewsModalErrorData),
                status: 'error',
                icon: 'mi-error-outline',
                cancelButtonText: commonMessages.close
            });
            this.setState({
                showCollectionMethodChangeModal: false,
                showInvoiceConfirmationModal: false
            });
        }
    };

    isBroker = () => this.props?.authUserData?.organizationType_PV?.toLowerCase() === 'vivium';

    isOpenForBRBandATKReq = (invoice) => this.isBroker() && (invoice?.isATKAvailable || invoice?.isBRBAvailable);

    handleActionButtons = (item) => {
        const translator = this.context;
        const { fromAccountLanding } = this.props;
        const accountNumber = _.get(fromAccountLanding, 'policyDetailsData.policyResponse.account.accountNumber');
        const isPolicyCancelled = _.get(fromAccountLanding, 'policyDetailsData.policyResponse.latestPeriod.canceled');
        const { invoiceData, actionButtonVisible, invoicePayment: { isPaymentDone } } = this.state;
        const { invoiceNumber, statementAmount, paidStatus } = item;
        if (invoiceData) {
            const data = invoiceData.find((obj) => obj.invoiceNumber === invoiceNumber);
            if (this.isOpenForBRBandATKReq(item) && actionButtonVisible) {
                return (
                    <React.Fragment>
                        <Button disabled={(data.flag === false)}
                            onClick={() => this.handleCollectionMethodChangeReqForEventType(accountNumber, invoiceNumber, BusinessConstant.BRB_PV, statementAmount, isPaymentDone, paidStatus, isPolicyCancelled)}>
                            {translator(messages.brbLabel)}
                        </Button>
                        <Button disabled={(data.flag === true)}
                            onClick={() => this.handleCollectionMethodChangeReqForEventType(accountNumber, invoiceNumber, BusinessConstant.DRQ_PV, statementAmount, isPaymentDone, paidStatus, isPolicyCancelled)}>
                            {translator(messages.atkLabel)}
                        </Button>
                    </React.Fragment>
                );
            }
        }
    };

    handleCollectionMethodChangeReqForEventType = (accountNumber, invoiceNumber, eventType, invoiceAmount, isPaymentDone, paidStatus, isPolicyCancelled) => {
        const { modalContext } = this.props;
        const isDRQ = eventType === BusinessConstant.DRQ_PV;
        const isBRB = eventType === BusinessConstant.BRB_PV;
        const isDRQValid = isDRQ && paidStatus && paidStatus !== BusinessConstant.INVOICE_PAID_STATUS_CANCELLED;
        const isBRBValid = isBRB && !isPolicyCancelled;

        if (isBRBValid) {
            this.handleCollectionMethodChangeReqForBRB(accountNumber, invoiceNumber, BusinessConstant.BRB_PV, invoiceAmount, isPaymentDone);
        } else if (isDRQValid) {
            this.handleCollectionMethodChangeReqForDRQ(accountNumber, invoiceNumber, BusinessConstant.DRQ_PV, invoiceAmount, isPaymentDone);
        } else {
            this.showPolicyCancelledPopup(modalContext);
        }
    };

    showPolicyCancelledPopup = (modalContext) => {
        modalContext.showAlert({
            title: messages.attentionLabel,
            message: messages.policyCancelledLabel,
            status: 'error',
            icon: 'mi-error-outline',
            cancelButtonText: commonMessages.close
        });
    };

    handleCollectionMethodChangeReqForDRQ = (accountNo, invoiceNumber, eventType, invoiceAmount, isPaymentDone) => {
        this.setState({
            showCollectionMethodChangeModal: false,
            showInvoiceConfirmationModal: true,
            invoicePayment: {
                accountNo,
                invoiceNumber,
                eventType,
                invoiceAmount,
                isPaymentDone
            },
        });
    };

    /*
     * Just validates that selected option is available at all
     * If data combination is allowed, then continues to CollectionMethodChange dialog
     * If data combination not allowed, shows error and stops process
     */
    handleCollectionMethodChangeReqForBRB = async (accountNo, invoiceNumber, eventType, invoiceAmount, isPaymentDone) => {
        const { authHeader, modalContext } = this.props;
        try {
            // NOTE: bad naming. it is just validation method, not handling method
            const changeCollectionMethodFlag = await GatewayBillingService.handlePortalBRBATKRequests(accountNo, invoiceNumber, eventType, authHeader);
            if (changeCollectionMethodFlag) {
                this.setState({
                    showCollectionMethodChangeModal: true,
                    showInvoiceConfirmationModal: false,
                    invoicePayment: {
                        accountNo,
                        invoiceNumber,
                        eventType,
                        invoiceAmount,
                        isPaymentDone
                    }
                });
            } else {
                this.setState({
                    showCollectionMethodChangeModal: false,
                    showInvoiceConfirmationModal: false,
                });
                modalContext.showAlert({
                    title: gatewayMessages.modalError,
                    message: gatewayMessages.gatewayViewsModalErrorData,
                    status: 'error',
                    icon: 'mi-error-outline',
                    cancelButtonText: commonMessages.close
                });
            }
        } catch (error) {
            this.setState({
                showCollectionMethodChangeModal: false,
                showInvoiceConfirmationModal: false,
            });
            const msg = EdgeErrorParser.getErrorMessage(error);
            modalContext.showAlert({
                title: gatewayMessages.modalError,
                message: (msg || gatewayMessages.gatewayViewsModalErrorData),
                status: 'error',
                icon: 'mi-error-outline',
                cancelButtonText: commonMessages.close
            });
        }
    };

    checkIfOpenInvoiceExists = (invoiceData) =>
        invoiceData.some((item) => (item.status === 'due' || item.status === 'billed') && item.paidStatus !== 'fullypaid');

    getOpenInvoiceData = (invoiceData) => {
        if (this.checkIfOpenInvoiceExists(invoiceData)) {
            return invoiceData.map((data) => ({
                dueDate: this.getFormattedDate(data.dueDate),
                paidStatus: data.paidStatus,
                paid: data.paid,
                outStanding: data.unpaid,
                invoiceTotal: data.amount,
                invoiceNumber: data.invoiceNumber,
                status: data.status,
                commission: data.commission_PV,
                invoiceCollectionMethod: this.getTypelistTranslation(data.invoiceCollectionMethod_PV, COLLECTIONMETHOD_PV, BC),
                isATKAvailable: data.isATKAvailable,
                isBRBAvailable: data.isBRBAvailable,
                invoiceDocUID: data.invoiceDocUID_PV,
                invoiceDate: this.getFormattedDate(data.invoiceDate),
                invoiceStartDate: this.getFormattedDate(data.invoiceStartDate_PV),
                invoiceEndDate: this.getFormattedDate(data.invoiceEndDate_PV),
                invoiceItemType: this.getTypelistTranslation(data.invoiceItemType_PV, INVOICETYPE_PV, BC),
                paymentDate: data.paymentDate_PV ? this.getFormattedDate(data.paymentDate_PV) : ''
            }));
        }
    };

    getPaymentScheduleData = (invoiceData) => invoiceData.map((data) => ({
        dueDate: this.getFormattedDate(data.dueDate),
        paidStatus: data.paidStatus,
        status: data.status,
        paid: data.paid,
        outStanding: data.unpaid,
        statementAmount: data.amount,
        invoiceTotal: data.amount,
        invoiceNumber: data.invoiceNumber,
        commission: data.commission_PV,
        invoiceCollectionMethod: this.getTypelistTranslation(data.invoiceCollectionMethod_PV, COLLECTIONMETHOD_PV, BC),
        isATKAvailable: data.isATKAvailable,
        isBRBAvailable: data.isBRBAvailable,
        invoiceDocUID: data.invoiceDocUID_PV,
        invoiceDate: this.getFormattedDate(data.invoiceDate),
        invoiceStartDate: this.getFormattedDate(data.invoiceStartDate_PV),
        invoiceEndDate: this.getFormattedDate(data.invoiceEndDate_PV),
        invoiceItemType: this.getTypelistTranslation(data.invoiceItemType_PV, INVOICETYPE_PV, BC),
        paymentDate: data.paymentDate_PV ? this.getFormattedDate(data.paymentDate_PV) : ''
    }));

    getTypelistTranslation = (value, typelist, xCenter) => {
        const translator = this.context;
        const { viewModelService } = this.props;
        if (value && typelist && xCenter) {
            const result = viewModelService.productMetadata.get(xCenter).types.getTypelist(typelist).getCode(value);
            return translator({
                id: result.name,
                defaultMessage: result.code
            });
        }
        return '-';
    };

    getDisbursementData = (disbursementsInfo) => disbursementsInfo.map((data) => ({
        disbursementDate: this.getFormattedDate(data.disbursementDate),
        amount: data.amount,
        iban: data.iban,
        nameBeneficiary: data.nameBeneficiary,
        reference: data.reference
    }));

    getDelinquencyLevel = (delinquencyLevel) => {
        const translator = this.context;
        return translator(DelinquencyUtil.getDelinquencyLevel(delinquencyLevel, messages.inGoodStandingLabel));
    };

    getDelinquencyDate = (delinquencyDate) => {
        return DelinquencyUtil.getDelinquencyDate(delinquencyDate, this.props.intl);
    };

    invoicePaidStatus = (items) => {
        const translator = this.context;
        return translator({
            id: `typekey.InvoicePaidStatus.${items.paidStatus}`,
            defaultMessage: items.paidStatus
        });
    }

    displayPDFIconAndInvoiceLink = (item) => {
        const { invoiceDocuments } = this.state;
        return invoiceDocuments?.some((d) => d.docUID === item.invoiceDocUID) && item.status !== 'planned';
    };

    getDocumentLink = (item, index, property) => {
        return (
            <div>
                {this.displayPDFIconAndInvoiceLink(item) ?
                    <span>
                        <Link icon="mi-picture-as-pdf" onClick={() => this.openInvoiceDocument(item.invoiceDocUID)}>{item[property.id]}</Link>
                    </span> :
                    <span>{item[property.id]}</span>}
            </div>
        );
    }

    openInvoiceDocument = (docUID) => {
        const { invoiceDocuments } = this.state;
        if (invoiceDocuments === undefined) {
            return;
        }

        const document = invoiceDocuments.find((d) => d.docUID === docUID);
        if (document !== undefined) {
            this.openDocument(document);
        }
    };

    openDocument = (document) => {
        const { publicID, sessionID, source_PV } = document;
        const downloadUrl = GatewayDocumentService.downloadDocument(publicID, sessionID, source_PV);
        window.open(downloadUrl);
    }

    calcInvoiceBRBATKFlag = (invoice) => {
        invoice.flag = false;
        if (invoice.invoiceCollectionMethod_PV === BusinessConstant.PRODUCERBILL_PV || invoice.eventType === BusinessConstant.DRQ_PV) {
            invoice.flag = true;
        }
        if (invoice.invoiceCollectionMethod_PV === BusinessConstant.BRB_PV || !invoice.eventType === BusinessConstant.DRQ_PV) {
            invoice.flag = false;
        }
    }

    getCell = (items, index, property) => {
        const translator = this.context;
        const tooltipMessage = {
            dueDate: translator(messages.dueDateLabel),
            paidStatus: translator(messages.paidStatusLabel)
        };
        let showPastDueAlert = false;
        if (
            moment()
                .startOf('day')
                .isAfter(moment(items.dueDate)
                    .format('YYYY-MM-DD'), ['year', 'month', 'day'])
            && items.outStanding.amount > 0
            && property.id === 'paidStatus'
        ) {
            // eslint-disable-next-line no-param-reassign
            items.paidStatus = translator(messages.pastDue);
            showPastDueAlert = true;
        } else {
            items.paidStatus = items.paidStatus === BusinessConstant.INVOICE_PAID_STATUS_CANCELLED ? items.paidStatus : this.invoicePaidStatus(items);
            showPastDueAlert = false;
        }

        const classNameShowPastDueAlert = classNames({
            [policyBillingStyles.negativePastDueAlert]: showPastDueAlert
        });

        return (
            <span title={tooltipMessage[property.id]} className={classNameShowPastDueAlert}>
                {items[property.id]}
            </span>
        );
    };

    renderHeader = () => {
        const translator = this.context;
        return (
            <div>
                <span>{translator(messages.paidStatusLabel)}</span>
                <Tooltip
                    placement="bottom"
                    content={translator(messages.paidTooltipLabel)}>
                    <IconButton
                        icon="mi-info" />
                </Tooltip>
            </div>
        );
    }

    getFormattedCurrency = (item, index, property) => {
        const translator = this.context;
        const tooltipMessage = {
            paid: translator(messages.PaidLabel),
            outStanding: translator(messages.outstandingLabel),
            statementAmount: translator(messages.statementAmountLabel)
        };

        return (
            <span
                title={tooltipMessage[property.id]}
            >
                <CurrencyField
                    id={`currency_${index}`}
                    value={item[property.id]}
                    readOnly
                    hideLabel
                    showOptional={false}
                />
            </span>
        );
    };

    getFormattedDate = (date) => {
        const { intl } = this.props;
        return intl.formatDate(new Date(date), { year: 'numeric', month: 'short', day: 'numeric' });
    };

    getPolicyPeriodDropDownOptions = (billingData) => {
//         const translator = this.context;
        if (_.isEmpty(billingData)) {
            return null;
        }
//         const now = moment();
//         let currentPolicyPeriodBillingSummary;
        return billingData.map((key, index) => {
            if (key.billingMethod === 'producer_bill_mandatory_policy_pv' || key.billingMethod === 'producer_bill_man_default_pv' || key.billingMethod === 'direct_bill_man_default_pv') {
                this.setState({ actionButtonVisible: false });
            }
            const policyPeriodName = `#${index + 1}: ${key.periodName}`;
            return {
                code: index,
                name: policyPeriodName
            };
        });
    };

    getPolicyStatusIcon = (billingData) => {
        if (billingData.isDelinquent) {
            return 'mi-warning';
        }
        return 'mi-check';
    };

    getPolicyStatusClassname = (billingData) => {
        return classNames(policyBillingStyles.policyBillingIcon, {
            [policyBillingStyles.policyBillingDelinquent]: billingData.isDelinquent,
            [policyBillingStyles.policyBillingGoodStanding]: !billingData.isDelinquent
        });
    };

    handleViewAccountBilling = () => {
        const {
            history,
            fromAccountLanding: {
                policyDetailsData: { policyResponse }
            }
        } = this.props;
        const {
            account: { accountNumber }
        } = policyResponse;
        const nextPath = `/accounts/${accountNumber}/billingAndPayment`;
        history.push({ pathname: nextPath, accountDetailsData: { accountNumber: accountNumber } });
    };

    render() {
        const {
            billingInvoiceData,
            billingOpenInvoiceData,
            billingDisbursementData,
            currentBillingData,
            policyPeriodDropDownOptions,
            selectedPeriod,
            isBillingInfoAvailable,
            showInvoiceConfirmationModal,
            showCollectionMethodChangeModal,
            invoicePayment
        } = this.state;
        const translator = this.context;

        const showPastDueLine = currentBillingData.aggregatedPastDueAmount?.amount > 0;
        const showCurrentPaymentLine = currentBillingData.nextDueAmount?.amount > 0;

        const overrides = {
            invoiceConfirmationPopup: {
                visible: showInvoiceConfirmationModal,
                invoicePayment: invoicePayment,
            },
            collectionMethodChangePopup: {
                visible: showCollectionMethodChangeModal,
                invoicePayment: invoicePayment,
            },
            billingPagePolicyStatusIcon: {
                icon: this.getPolicyStatusIcon(currentBillingData),
                className: this.getPolicyStatusClassname(currentBillingData)
            },
            tabBillingSummaryTotalPremium: {
                value: currentBillingData.totalPremium
            },
            tabBillingSummaryTaxesAndFees: {
                value: currentBillingData.taxesAndFees
            },
            tabBillingSummaryTotalCosts: {
                value: currentBillingData.totalCost
            },
            policyDelinquencyLevel: {
                value: this.getDelinquencyLevel(currentBillingData.delinquencyLevel_PV)
            },
            policyDelinquencyDate: {
                value: this.getDelinquencyDate(currentBillingData.delinquencyDate_PV)
            },
            tabBillingSummaryPastDueContainer: {
                visible: showPastDueLine
            },
            tabBillingSummaryPastDue: {
                value: currentBillingData.aggregatedPastDueAmount
            },
            tabBillingSummaryPastDueDate: {
                content: this.getFormattedDate(currentBillingData.pastDueDate)
            },
            tabBillingSummaryNextPaymentContainer: {
                visible: showCurrentPaymentLine
            },
            tabBillingSummaryNextPayment: {
                value: currentBillingData.nextDueAmount
            },
            billingSummaryNextDueDate: {
                content: this.getFormattedDate(currentBillingData.nextDueDate)
            },
            tabBillingSummaryTotalDue: {
                value: currentBillingData.totalDueAmount
            },
            tabBillingSummaryBillingMethod: {
                value: currentBillingData?.billingMethod ? translator({
                    id: `typekey.BillingMethod.${currentBillingData.billingMethod}`,
                    defaultMessage: currentBillingData.billingMethod
                }) : '-'
            },
            tabBillingSummaryPaymentPlan: {
                value: currentBillingData?.paymentPlan ? currentBillingData.paymentPlan : '-'
            },
            tabBillingSummaryActivePaymentMethod: {
                value: currentBillingData?.activePaymentMethod_PV ? translator({
                    id: `typekey.PolicyPaymentMethod_CxPV.${currentBillingData.activePaymentMethod_PV}`,
                    defaultMessage: currentBillingData.activePaymentMethod_PV
                }) : '-'
            },
            tabBillingSummarySendingChannel: {
                value: currentBillingData?.sendingChannel_PV ? translator({
                    id: `typekey.InvoiceDeliveryMethod.${currentBillingData.sendingChannel_PV}`,
                    defaultMessage: currentBillingData.sendingChannel_PV
                }) : '-'
            },
            tabBillingSummaryMandatereference: {
                visible: !_.isEmpty(currentBillingData.mandateReference_PV),
                value: currentBillingData.mandateReference_PV
            },
            tabBillingSummaryMandateStatus: {
                visible: !_.isEmpty(currentBillingData.mandateStatus_PV),
                value: translator({
                    id: `typekey.MandateStatus_CxPV.${currentBillingData.mandateStatus_PV}`,
                    defaultMessage: currentBillingData.mandateStatus_PV
                })
            },
            paymentScheduledTable: {
                data: billingInvoiceData
            },
            openInvoiceDataTable: {
                data: billingOpenInvoiceData
            },
            disbursementDataTable: {
                data: billingDisbursementData
            },
            policyPeriodDropDown: {
                availableValues: policyPeriodDropDownOptions,
                value: selectedPeriod
            },
            billingInfoContainer: {
                visible: isBillingInfoAvailable
            },
            billingEmptyContainer: {
                visible: !isBillingInfoAvailable
            },
            warningMessage: {
                value: translator(messages.noInvoicePaymentorDisbursementLabel)
            }
        };
        const resolvers = {
            resolveClassNameMap: policyBillingStyles,
            resolveComponentMap: {
                InvoiceConfirmationPopup,
                CollectionMethodChangePopup
            },
            resolveCallbackMap: {
                handleCancel: this.handleCancel,
                handleCollectionMethodChangeConfirm: this.handleCollectionMethodChangeConfirm,
                handleInvoiceConfirmationConfirm: this.handleCollectionMethodChangeReqForBRB,
                getDocumentLink: this.getDocumentLink,
                handleActionButtons: this.handleActionButtons,
                getCell: this.getCell,
                renderHeader: this.renderHeader,
                getFormattedCurrency: this.getFormattedCurrency,
                handlePolicyPeriodChange: this.handlePolicyPeriodChange,
                handleViewAccountBilling: this.handleViewAccountBilling,
                sortDate: DatatableUtil.sortDate,
                sortString: DatatableUtil.sortString,
                sortCurrency: DatatableUtil.sortCurrency
            }
        };

        const policyPage = <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
        return <div className={policyBillingStyles.policybilling}>{policyPage}</div>;
    }
}

export const BillingComponent = PolicyBilling;
export default withViewModelService(withIntl(withAuthenticationContext(withModalContext(PolicyBilling))));
